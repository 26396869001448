@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    box-sizing: border-box;
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;

    /* Base / light mode colors */
    --color-background: #FFFFFF;
    --color-body: #333333;
    --color-body-light: #5F5F5F;
    --color-code-background: #F4F4F4;
    --color-primary: #2F6DF2;
    --color-primary-transparent: #3B98F680;
    --color-hover: #2F6DF2B2;

    --color-success: #019607;

    --color-a: #2D61D226;
    --color-b: #2B55B726;
    --color-c: #B198FF26;
    --color-d: #FF86E326;
    --color-gradient: linear-gradient(90deg,
        var(--color-a) 0%,
        var(--color-b) 37%,
        var(--color-c) 75%,
        var(--color-d) 100%);
  }

  /* Dark mode theme colors */
  :root[style*=dark] {
    --color-background: #2A2C3F !important;
    --color-body: #F8F8F8 !important;
    --color-body-light: #A2A3C8 !important;
    --color-code-background: #1A1B29 !important;
    --color-primary: #007AA6 !important;
    --color-primary-transparent: #007AA680;
    --color-hover: #007AA6E5;
  }
  
  /* Black mode theme colors */
  :root[style*=black] {
    --color-background: #0E0E0E !important;
    --color-body: #F8F8F8 !important;
    --color-body-light: #CDCDCD !important;
    --color-code-background: #313131 !important;
    --color-primary: #709EFF !important;
    --color-primary-transparent: #709EFF80;
    --color-hover: #709EFFE5;
  }

  * {
    scroll-behavior: smooth;
  }

  body {
    background: var(--color-background);
  }

  h1 {
    @apply font-thin;
    @apply text-body;
    @apply text-7.5xl;
  }

  @media (max-width: 767px) {
    h1 {
      @apply text-6xl;
    }
  }

  h2 {
    @apply font-bold;
    @apply text-body;
  }

  button {
    @apply hover:opacity-90
  }

  .blur-backdrop::before {
    content: "";
    position: absolute;
    inset: 0;
    opacity: 95%;
    background: var(--color-background);
    z-index: -1;
  }
}